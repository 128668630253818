import { DeploymentStage, isDeploymentStage, stage } from "@tc/env-stage"

// ONE Cognito for dev/tst1
const AWS_USER_POOLS_ID = "ap-southeast-2_Bj591GWUJ"

// Cognito for tst2
const AWS_USER_POOLS_TST2_ID = "ap-southeast-2_9fgjf5fr1"

// Cognito for Prod
const AWS_USER_POOLS_PROD_ID = "ap-southeast-2_6fxucFWPj"

const AwsUserPoolsId: Record<DeploymentStage, string> = {
  dev: AWS_USER_POOLS_ID,
  tst1: AWS_USER_POOLS_ID,
  tst2: AWS_USER_POOLS_TST2_ID,
  prd: AWS_USER_POOLS_PROD_ID,
}

export const cognitoPool = (): string => {
  if (isDeploymentStage(stage)) {
    return AwsUserPoolsId[stage]
  } else {
    throw new Error(`Unrecognised stage ${stage}`)
  }
}

export const Issuer = () => {
  return `https://cognito-idp.ap-southeast-2.amazonaws.com/${cognitoPool()}`
}

export const wellKnown = async () => {
  const issuer = Issuer()
  const response = await fetch(issuer + "/.well-known/openid-configuration")
  return response.json()
}
