import { strict as assert } from "assert"

assert(
  process.env["ENV"],
  "Environment variable ENV not set. Examples: dev | tst1 | stg | prd | john",
)
assert(
  process.env["ENV"].length <= 7,
  `Environment variable ENV value ${process.env["ENV"]} is too long. It cannot be more than 7 characters.`,
)

/**
 * Stages we deploy code too.
 */
export const DeploymentStages = {
  DEV: "dev",
  TST1: "tst1",
  TST2: "tst2",
  PRD: "prd",
} as const
export type DeploymentStage =
  (typeof DeploymentStages)[keyof typeof DeploymentStages]

/**
 * Typeguard
 */
export const isDeploymentStage = (key: string): key is DeploymentStage => {
  return Object.values(DeploymentStages).includes(key as DeploymentStage)
}

/**
 * All known AWS environments.
 */
export enum Stage {
  DEV = "dev",
  TST1 = "tst1",
  TST2 = "tst2",
  PRD = "prd",
  TOOLS = "tools",
}

export const appName: string = getAppName()

/*
 * APP_NAME_NOT_SET
 * If you encounter 'APP_NAME_NOT_SET_SEE_DOCS' go to where you set your env, and underneath, set NX_TASK_TARGET_PROPJECT TO 'appName'.
 */
export function getAppName() {
  return process.env["NX_TASK_TARGET_PROJECT"]
    ? process.env["NX_TASK_TARGET_PROJECT"]
    : "APP_NAME_NOT_SET_SEE_DOCS"
}

// Env is the suffix we are using
export let env = ""

// stage is the kind of environment we deploy to, like dev or production.
export let stage = ""

// Tools defines what tools environment in our tools account we will use.
export const toolsEnv: string = process.env["TOOLS_ENV"]
  ? process.env["TOOLS_ENV"]
  : Stage.TOOLS

/**
 * Is the current environment the production environment?
 */
export let isProduction: boolean = stage == Stage.PRD

/**
 * Is the current `stage` the testing/qa environment?
 */
export let isTesting1: boolean = stage == Stage.TST1

/**
 * Is the current `stage` the testing/qa environment?
 */
export let isTesting2: boolean = stage == Stage.TST2

/**
 * Is the current `stage` the dev environment?
 */
export let isDev: boolean = stage == Stage.DEV

/**
 * Is the current `stage` the tools environment?
 */
export let isTools: boolean = stage == Stage.TOOLS

export function envSpecificName(name: string): string {
  return name + "-" + env
}

export function stageSpecificName(name: string): string {
  return name + "-" + stage
}

export function toolsEnvSpecificName(name: string): string {
  return name + "-" + toolsEnv
}

/**
 * Allow changing `env` and `stage` so our tools setup can to cross account deployments.
 * If Stage is not set or `newEnv` is undefined, we fallback to the "dev" environment.
 */
export const setStage = (newEnv: string) => {
  stage = newEnv
  isProduction = stage == Stage.PRD
  isTesting1 = stage == Stage.TST1
  isTesting2 = stage == Stage.TST2
  isDev = stage == Stage.DEV
  isTools = stage == Stage.TOOLS
}

export const setEnv = (newEnv: string) => {
  // Limit env to maximum length of a db cluster identifier
  env = newEnv.substring(0, 63)
}

// Initialisation
export const setDefaultEnv = (defaultEnv: string) => {
  setEnv(process.env["ENV"] || defaultEnv)
  setStage(process.env["STAGE"] || process.env["ENV"] || defaultEnv)
}
setDefaultEnv(Stage.DEV)
